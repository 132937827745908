import { FlexBox, Modal, TextField, theme } from '@rario/shared-components'
import WarningIcon from 'assets/svg/WarningIcon'

const RestrictedUserModal = ({
  showContactSupportText = false,
}: {
  showContactSupportText?: boolean
}) => {
  return (
    <Modal
      bodyBackground={theme.colors.whites[0]}
      visible
      background="#11151C"
      showCloseIcon={false}
      modalPosition={'flex-end'}
      variant={'fullScreen'}
      desktopTop={'-50px'}
      top={'-50px'}
    >
      <FlexBox flexDirection="column" alignItems="center" my="40px" mx="10px">
        <WarningIcon width={'100'} height={'100'} />

        <TextField
          fontFamily={theme.fonts.primary}
          fontSize="18px"
          fontWeight={700}
          lineHeight="26px"
          textTransform="uppercase"
          textAlign="center"
          pb="0px"
          mt="40px"
          mb="15px"
        >
          BLOCKED!
        </TextField>

        <TextField
          fontSize="14px"
          fontWeight={400}
          lineHeight="20px"
          color={theme.colors.whites[10]}
          textAlign="center"
          pb="0px"
        >
          Due to regulatory guidelines, real-money gaming is restricted in your current region.
        </TextField>
        {showContactSupportText && (
          <TextField
            fontSize="14px"
            fontWeight={400}
            lineHeight="20px"
            color={theme.colors.whites[10]}
            textAlign="center"
            pb="0px"
          >
            If you are an existing user, please reach out to support@rario.com.
          </TextField>
        )}
      </FlexBox>
    </Modal>
  )
}

export default RestrictedUserModal
