import { IPL_BASE_PATH } from 'constant/iplTeamLanding'
import { useRouter } from 'next/router'

const usePageControl = () => {
  const router = useRouter()
  const { pathname, asPath, query } = router
  const showNavigation = pathname === '/' || pathname === '/buy'
  const isHomeLandingPage = pathname === '/'
  const showNewsSection = pathname === '/'
  const isUserProfilePage = pathname.includes('/profile')
  const isSharedUserProfilePage = pathname.includes('/profile/') && pathname.includes('/[username]')
  const isMarketplacePage = pathname === '/marketplace'
  const isPackPlp = pathname === '/packs'
  const isPickPlp = pathname === '/picks'
  const isBuy = isMarketplacePage || isPackPlp || isPickPlp
  const isRarioHomeLanding = pathname === '/home-landing'
  const isLeaguePage = pathname === '/leagues'

  const isInvoiceDownloadPage = pathname.includes('/invoices')
  const isReferEarn = pathname === '/refer-earn' && query?.referer !== 'home'
  const isWallet = pathname.includes('/wallet')
  const isKycPage = pathname.includes('/kyc')
  const isAddFundsPage = pathname.includes('/add-funds')
  const isWithdrawFundsPage = pathname.includes('/withdraw-funds')
  const isCommunityPage = pathname === '/community'
  const isPackPdp = pathname.includes('/packs/')
  const isPickPdp = pathname.includes('/picks/')
  const isCardPdp = pathname.includes('/listings/')
  const isTrialCardPdp = asPath.includes('/tr_')
  const isTaxSummaryPage = pathname === '/tax-summary'

  const isHistoryPage = pathname.includes('/history')
  const isCheckoutPage = pathname.includes('/checkout/[status]')
  const isAboutUsPage = pathname === '/about-us'
  const isDream11Landing = pathname === '/dreamsports'
  const isCircleStatusPage = pathname === '/transaction/[status]'
  const isPpcLanding = pathname === '/ppc'
  const isDreamSquadLanding = pathname === '/dreamsquad'
  const isDreamTeamLanding = pathname === '/dreamteam'
  const isCricketFandomLanding = pathname === '/cricketfandom'
  const isPaymentPage =
    asPath.includes('checkout/payment-method/') || asPath.includes('checkout/payment/')
  const isInaiPage = asPath.includes('checkout/payment/')
  const isGTLandingPage = pathname === `${IPL_BASE_PATH}/gt`
  const isRCBLandingPage = pathname === `${IPL_BASE_PATH}/rcb`
  const isKKRLandingPage = pathname === `${IPL_BASE_PATH}/kkr`
  const isPBKSLandingPage = pathname === `${IPL_BASE_PATH}/pbks`
  const isIPLCategoryPage = pathname === IPL_BASE_PATH
  const isBlockedPage = pathname === '/blocked'
  const isArcadePage = pathname === '/arcade'
  const isArcadeGamePage = pathname === '/arcade/trumps'
  const isTrumpsPage = pathname.includes('/arcade/trumps')
  const isBurnEarnPage = pathname === '/burn-earn' || pathname === '/burn-status'
  const isLoginSignupPage =
    pathname === '/login' ||
    pathname === '/signup' ||
    pathname === '/forgot-password' ||
    pathname === '/reset-password'
  const isBurnEarnListing = pathname === '/burn-earn'
  // TODO Ui issue for header control on Pages
  const isContestPage =
    pathname.endsWith('/level-up') || pathname.endsWith('/referral') || pathname.endsWith('/social')
  const isIplPage = pathname.includes(IPL_BASE_PATH)
  const isIplLandingPage =
    isGTLandingPage ||
    isRCBLandingPage ||
    isKKRLandingPage ||
    isPBKSLandingPage ||
    isIPLCategoryPage
  const isSachinLandingPage = pathname === '/sachin'
  const isBuyBackPage = pathname.includes('/buy-back')

  const headerControl =
    pathname.includes('/pack-reveal') ||
    pathname === '/app-download' ||
    isBuy ||
    isUserProfilePage ||
    isRarioHomeLanding ||
    isDream11Landing ||
    isPpcLanding ||
    isDreamSquadLanding ||
    isDreamTeamLanding ||
    isCricketFandomLanding ||
    isContestPage ||
    isInaiPage ||
    isReferEarn ||
    isTaxSummaryPage ||
    isInvoiceDownloadPage ||
    isArcadePage ||
    pathname === '/d3'

  const backButtonControl =
    pathname.includes('/transaction') ||
    pathname.includes('/payout') ||
    pathname.includes('/checkout/[status]') ||
    pathname.includes('/confirmation-email') ||
    isHomeLandingPage ||
    isBuy ||
    isUserProfilePage ||
    isRarioHomeLanding ||
    isAboutUsPage ||
    isDream11Landing ||
    isPpcLanding ||
    isDreamSquadLanding ||
    isDreamTeamLanding ||
    isCricketFandomLanding ||
    isIplLandingPage ||
    isSachinLandingPage

  const freshChatControl =
    pathname.includes('/transaction') ||
    pathname.includes('/contact-us') ||
    pathname.includes('/checkout/[status]') ||
    isKycPage ||
    isCommunityPage ||
    isBlockedPage

  return {
    showNavigation,
    isHomeLandingPage,
    showNewsSection,
    backButtonControl,
    isUserProfilePage,
    isMarketplacePage,
    isInvoiceDownloadPage,
    isReferEarn,
    isBuy,
    headerControl,
    isPackPdp,
    isPickPlp,
    isWallet,
    isKycPage,
    isAddFundsPage,
    isWithdrawFundsPage,
    isHistoryPage,
    isCheckoutPage,
    isAboutUsPage,
    isSharedUserProfilePage,
    isPickPdp,
    isCardPdp,
    isTrialCardPdp,
    freshChatControl,
    isCircleStatusPage,
    isDream11Landing,
    isPpcLanding,
    isDreamSquadLanding,
    isDreamTeamLanding,
    isCricketFandomLanding,
    isPaymentPage,
    isIplLandingPage,
    isSachinLandingPage,
    isIplPage,
    isInaiPage,
    isTrumpsPage,
    isBurnEarnPage,
    isBurnEarnListing,
    isLeaguePage,
    isLoginSignupPage,
    isArcadeGamePage,
    isBuyBackPage,
  }
}

export default usePageControl
