const API_CONSTANTS = {
  AUTH_TOKEN: `/api/auth/token`,
  GET_USER_BY_ID: `/loki/users/{id}`, // public
  USER_PASSWORD_RESET: `/dbconnections/change_password`, // not in use
  GET_STATS: '/contents/player/stats?catalogId={id}', // not in use
  PATCH_USER: `/loki/users/profile`,
  PATCH_USER_BILLING_DETAILS: `/users/profile`,
  GET_USER_POLICY: `/loki/users/policies`, // not in use
  GET_USER_WALLET_BALANCE: `/wallet/balance`,
  GET_USER_TRANSACTION_HISTORY: `/search/wallet/transaction`,
  GET_USER_SALES_ORDER_HISTORY: `/loki/users/sales/order/group`,
  USER_PREFERENCES: `/loki/users/preferences`,
  CREATE_USER_OTP: `/loki/users/otp`,
  USER_OTP_VERIFICATION_BY_UUID: `/loki/users/otp`,
  CREATE_USER_RESERVATION: '/loki/users/order/reservation',
  GET_USER_RESERVATION_PURCHASE_STATUS: '/assets/purchase/status?catalogId={id}',
  ACCEPT_POLICY: `/loki/users/policy/acceptance`, // not in use
  GET_PROFILING_CONTENT: `/contents`, // not in use
  CREATE_USER_ORDER: '/loki/users/order',
  CREATE_USER_ORDER_SESSION: '/loki/users/order/checkout/payment/session', // not in use
  GET_USER_ORDER: '/loki/users/sales/order/{id}',
  GET_USER_ORDER_INVOICES: '/api/invoice/orders/{orderId}',
  PATCH_USER_ORDER: '/loki/users/sales/order/group/{id}',
  GET_USER_ORDER_GROUP: '/loki/users/sales/order/group/{id}',
  GET_SELLER_ORDER_GROUP: '/loki/users/sales/order/group/{id}/seller',
  GET_NFT_TOKEN_DETAIL: '/token/{tokenId}', // not in use
  GET_NFT_LISTING_BY_ID: '/token/listing/{listingId}',
  POST_NFT_TOKEN_LISTING: '/token/listing',
  POST_NFT_TOKEN_LISTING_APPROVAL: '/token/listing/confirmation', // not in use
  PATCH_NFT_TOKEN_LISTING_PRICE: '/token/listing/{listingId}',
  REVEAL_PACK_ITEM: '/loki/users/sales/order-item/{id}/reveal',
  REVEAL_PACK: '/loki/users/sales/order/{id}/reveal',
  NFT_SALES_HISTORY_TOKEN:
    '/catalog/{catalogId}/sales/history?page={page}&limit={limit}&tokenId={tokenId}',
  GET_NFT_SALE_LISTING:
    '/token/listing?page={currentPage}&limit={limit}&catalogId={id}&sortBy={sortBy}&sortDir={sortDir}',
  DELETE_NFT_LISTING: '/token/listing/{listingId}',
  GET_KYC_APPLICANT_ID: `/kyc/applicant`,
  GET_KYC_SDK_TOKEN: `/kyc/sdk-token`,
  CREATE_KYC_CHECK: `/kyc/check`,
  CREATE_USER_ORDER_PAYMENT: '/payments/checkout/order',
  GET_USER: `/users/profile`,
  SAVE_CARD_DETAILS: `/card-tokenization/cards`,
  GET_PUBLIC_KEY: `/wallet/payment/public/encryption`,
  CREATE_PAYMENT: `/wallet/payment`,
  GET_CIRCLE_PAYMENT_STATUS: `/wallet/payment/{paymentId}/status`,
  GET_PAYMENT_GATEWAY_TRANSACTION_STATUS: `/wallet/payment/{paymentId}/status`, // not in use
  ORDER_WALLET_PAYMENT: `/wallet/order/V2/payment`,
  GEO_CHECK: `/trove/geo-check/{ip}`,
  GET_LATEST_SALES: `/marketplace-sales/latest_sales`,
  GET_XP_VALUE: '/token-info/xp',
  GET_NFT_TAG_STATUS: '/token-info/in-use',
  EXCHANGE_RATE: '/forex/rate/USD',
  GET_USER_ACTIVITY: `/search/users/activity?page={page}&limit=10`,
  GET_CMS_PAGE_CONTENTS: `/trove/page/{pageId}`,
  GET_SUCCESS_REFERAL_DATA: `/search/users/activity?action=referral&page={page}&limit=10`,
  POST_CREATE_REFFERAL_DATA: `/campaign/users/referral-code`,
  USER_REFERRAL_DATA: `/campaign/coupon/referral-rewards?isRefereeFirstOrderCompleted={isRefereeHasOrders}`,
  GET_INAI_ORDER: `/payments/order-payment/order`,
  CANCEL_INAI_ORDER: `/payments/order-payment/cancel`,
  ORDER_GROUP_RECONCILIATION: `/loki/users/sales/order/group/{id}/reconciliation`,
  FETCH_BANK_ACCOUNTS: '/blade/users/bank-accounts',
  SAVE_BANK_ACCOUNT: '/blade/users/bank-account',
  CREATE_PAYOUT: '/blade/users/payout',
  WALLET_PAYOUT_STATUS: '/blade/users/{id}/payout',
  DELETE_BANK_ACCOUNT: '/blade/users/bank-account/{id}',
  SAVE_PAN_CARD: '/users/pan',
  ON_USER_LOGOUT: `/webhook/users-activity`,
  GAME_API_D3: `/d3/game/api`,
  GET_APP_CONFIG: `/api/configuration`,
  TOKEN_STATUS: '/token/reconciliation',
  GET_CAMPAIGN_DATA: `/campaign/coupon/{registerationSource}/discount`,
  GET_REFERRAL_COUNT: `/campaign/coupon/referral/count`,
  GET_USER_TAX_SUMMARY: '/api/reports/v2/tax-pnl',
  GET_CONTESTS: '/api/contests',
  GET_UPCOMING_MATCHES: '/d3/pub/game/api',
  GET_MARKETPLACE_WIDGET: '/api/marketplace-widget',
  GET_PACKS_WIDGET: '/api/packs-widget?league={league}&userCohort={userCohort}',
  GET_PICKS_WIDGET: '/api/picks-widget',
  GET_LEAGUE_FILTERS: '/api/league-filters',
  GET_ALL_TDS_CERTIFICATES: '/api/reports/tax-certificates',
  GET_TDS_CERTIFICATES_WITH_ID: '/api/reports/tax-certificates/{certificate_id}',
  CREATE_USER_KYC_ATTEMPTS: '/users/kyc/attempts',
  BURN_CARDS: '/token/exchange',
  BURN_CARDS_CONFIG: '/app-campaigns?type=3&status=active',
  VERIFY_EMAIL_RESET_PASSWORD: '/auth/verification/start',
  VERIFY_EMAIL_RESET_PASSWORD_TOKEN: '/auth/verification?token={token}',
  VERIFY_EMAIL_TOKEN_END: '/auth/verification/end?token={token}',
  RESET_PASSWORD_TOKEN_END: '/auth/reset/password?token={token}',
  DREAM_AUTH_LOGIN: `${process.env.DREAM_AUTH_BASE_URL}/auth/login`,
  DREAM_AUTH_SIGNUP: `${process.env.DREAM_AUTH_BASE_URL}/auth/register`,
  DREAM_AUTH_SOCIAL_LOGIN: `${process.env.DREAM_AUTH_BASE_URL}/auth/social/authenticate`,
  DREAM_AUTH_VALIDATE_SESSION: `${process.env.DREAM_AUTH_BASE_URL}/auth/session/validate`,
  DREAM_AUTH_CREATE_SESSION: `${process.env.DREAM_AUTH_BASE_URL}/auth/session/create`,
  DREAM_AUTH_DELETE_SESSION: `${process.env.DREAM_AUTH_BASE_URL}/auth/session/delete`,
  DREAM_AUTH_REFRESH_TOKEN: `${process.env.DREAM_AUTH_BASE_URL}/auth/token/refresh`,
  DREAM_AUTH_LOGOUT: `${process.env.DREAM_AUTH_BASE_URL}/auth/logout`,
  AUTH_V2_LOGIN: `/api/authv2/login`,
  AUTH_V2_SIGNUP: `/api/authv2/signup`,
  USER_PROFILE: '/api/users/profile',
  GOOGLE_AUTH_TOKEN: 'https://oauth2.googleapis.com/token',
  GOOGLE_AUTH: '/api/authv2/googleauth',
  GOOGLE_AUTH_VALIDATE_CAPTCHA:
    'https://recaptchaenterprise.googleapis.com/v1/projects/rario-prod/assessments?key={apiKey}',
  GET_TYPESENSE_OBJECT: 'https://{typesenseHost}/collections/{collection}/documents/{id}',
  BUYBACK_USER_CARDS: '/loki/users/vault/burn',
  BUYBACK_REPORT: '/api/buyback/report',
  BUYBACK_ESTIMATE: '/loki/users/vault/burn/estimate',
}

export default API_CONSTANTS
