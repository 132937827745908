import { BuybackEstimateProps } from 'interfaces/buyBack'
import { createContext, Dispatch, SetStateAction } from 'react'

export const BuyBackEstimateContext = createContext<{
  buybackEstimateData: BuybackEstimateProps | undefined
  setBuybackEstimateData: Dispatch<SetStateAction<BuybackEstimateProps | undefined>>
  userPacksCount: number | undefined
  setUserPacksCount: Dispatch<SetStateAction<number | undefined>>
}>({
  buybackEstimateData: undefined,
  setBuybackEstimateData: () => {},
  userPacksCount: 0,
  setUserPacksCount: () => {},
})
