import { ReactNode, useContext, useMemo, useState } from 'react'

import { BuyBackEstimateContext } from './BuyBackEstimateContext'
import { BuybackEstimateProps } from 'interfaces/buyBack'

export const useBuyBackEstimate = () => useContext(BuyBackEstimateContext)

const BuyBackEstimateProvider = ({ children }: { children: ReactNode }) => {
  const [buybackEstimateData, setBuybackEstimateData] = useState<BuybackEstimateProps | undefined>()
  const [userPacksCount, setUserPacksCount] = useState<number | undefined>(0)

  const value = useMemo(
    () => ({
      buybackEstimateData,
      setBuybackEstimateData,
      userPacksCount,
      setUserPacksCount,
    }),
    [buybackEstimateData, setBuybackEstimateData, userPacksCount, setUserPacksCount]
  )

  return <BuyBackEstimateContext.Provider value={value}>{children}</BuyBackEstimateContext.Provider>
}

export default BuyBackEstimateProvider
