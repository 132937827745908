import { customStyled, FlexBox } from '@rario/shared-components'

export const DropIcon = customStyled(FlexBox)<{ isFaqOpen: boolean }>`
    transform: ${({ isFaqOpen = false }) => (isFaqOpen ? 'rotateZ(180deg)' : 'rotateZ(0deg)')};
    transition: transform 0.4s;
`
export const AnswerText = customStyled(FlexBox)<{ isFaqOpen: boolean }>`
    max-height: ${({ isFaqOpen = false }) => (isFaqOpen ? '500px' : '0px')};
    transition: max-height 0.4s ease-in-out;
    flex-direction: column;
`
export const Lineseparator = customStyled(FlexBox)`
    width:100%;
    height:1px;
    background: rgba(104, 107, 112, 0.3);
`
