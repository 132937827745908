import { CheckboxButtonWrapper } from './Button.styles'
import { theme } from '@rario/shared-components'
import CheckBoxIcon from 'assets/svg/CheckBoxIcon'

type CheckboxButtonType = {
  onClick?: () => void
  selected?: boolean
}

const CheckboxButton: React.FunctionComponent<CheckboxButtonType> = ({ onClick, selected }) => {
  return (
    <CheckboxButtonWrapper onClick={onClick} selected={selected}>
      {selected && <CheckBoxIcon fillOuter={theme.colors.black} fillInner={theme.colors.white} />}
    </CheckboxButtonWrapper>
  )
}

export default CheckboxButton
