import { getAppConfig } from 'api/CMS.api'
import { CustomUserContext } from 'contexts/CustomUserContext'
import { countryCurrenciesMap } from 'hooks/useCurrencyConversion'
import useCurrencyDetails from 'hooks/useCurrencyDetails'
import { AppConfig } from 'interfaces'
import { AvailableCurrency, CurrencyDetails, CurrencyListItem } from 'interfaces/currency'
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { AppConfigContext, CountryIsoCode, defaultAppConfig } from './AppConfigContext'
import { GetCookie } from 'api'
import { CampaignData } from 'interfaces/burnEarn'
import { burnCardCampaignConfig } from 'api/NFT.api'
import { useCustomAuth } from './CustomAuthProvider'
import { emitCustomEvent } from 'components/CustomEvents'
import { IApiException } from 'api/Api.exception'

export const useCustomUser = () => useContext(CustomUserContext)

const AppConfigProvider = ({ children }: { children: ReactNode }) => {
  const [cookies, setCookie] = useCookies(['x_ip_country', 'x_ip'])
  const [appConfig, setAppConfig] = useState<AppConfig>(defaultAppConfig)
  const preferredCurrencyDetails = useCurrencyDetails(appConfig.preferredCurrency, true)
  const [campaignData, setCampaignData] = useState<CampaignData[]>([])
  const { accessToken } = useCustomAuth()

  const setDefaultCurrency = (preferredCurrency?: AvailableCurrency) => {
    const savedCurrency = localStorage.getItem('currDetails')
    const currencyDetails: CurrencyDetails = savedCurrency ? JSON.parse(savedCurrency) : null
    setPreferredCurrency(currencyDetails?.selectedCurrency || preferredCurrency)
  }

  const fetchCampaignConfig = async () => {
    try {
      const {
        data: { results },
      } = await burnCardCampaignConfig(accessToken)
      if (results?.length > 0) {
        setCampaignData(results)
        setAppConfig((previous) => ({ ...previous, campaignData: results }))
      }
    } catch (err) {
      const error = err as IApiException
      const errorResponse = error.response ? error.response : { message: error.message }
      emitCustomEvent('errorCodes', errorResponse)
    }
  }
  useEffect(() => {
    accessToken && fetchCampaignConfig()
  }, [accessToken])

  useEffect(() => {
    setDefaultCurrency()
  }, [])

  useEffect(() => {
    getAppConfig().then((res) => {
      const data = res.data
      setAppConfig((previous) => ({ ...previous, ...data }))
      const ipCountry = GetCookie('x_ip_country')
      const ip = GetCookie('x_ip')
      setCookie('x_ip_country', ipCountry)
      setCookie('x_ip', ip)
    })
  }, [])

  useEffect(() => {
    const ipCountry = cookies.x_ip_country
    if (ipCountry) {
      const isIndianIP = ipCountry === CountryIsoCode.INDIA
      const preferredCurrency =
        appConfig.preferredCurrency ?? countryCurrenciesMap[ipCountry as CountryIsoCode] ?? 'USD'
      setDefaultCurrency(preferredCurrency)
      setAppConfig((previous) => ({ ...previous, isIndianIP }))
    }
  }, [cookies?.x_ip_country])

  const getCurrencyDetailsToStore = ({
    selectedCurrency,
    symbol,
    conversionRate,
    locale,
  }: CurrencyListItem): CurrencyDetails => {
    return {
      selectedCurrency,
      symbol,
      staticConversionRate: conversionRate,
      locale,
    }
  }

  useEffect(() => {
    if (preferredCurrencyDetails) {
      localStorage.setItem(
        'currDetails',
        JSON.stringify(getCurrencyDetailsToStore(preferredCurrencyDetails))
      )
    }
  }, [preferredCurrencyDetails])

  const setPreferredCurrency = (preferredCurrency: AvailableCurrency) => {
    setAppConfig((previous) => ({ ...previous, preferredCurrency }))
  }

  const isAddFundsDisabled =
    appConfig.disableAddFundsDate && new Date() >= new Date(appConfig.disableAddFundsDate)

  const value = useMemo(
    () => ({
      ...appConfig,
      setPreferredCurrency,
      campaignData,
      isAddFundsDisabled,
      disableMarketPlaceActivities: true, // TODO: Need to remove this before taking it to production
    }),
    [appConfig]
  )

  return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>
}

export default AppConfigProvider
