import { FlexBox, customStyled, theme } from '@rario/shared-components'
import { ScarcityTypes } from 'interfaces/buyBack'

const cardColor = {
  silver: 'linear-gradient(180deg, #D9D9D9 0%, #8E8E8E 100%)',
  gold: 'linear-gradient(180deg, #FAD183 0%, #DEAB4B 100%)',
  bronze: 'linear-gradient(180deg, #B65F3A 0%, #7E2F2F 100%)',
  black: '#000',
}

export const SeeCardValuesWrapper = customStyled(FlexBox)`
  margin-top: 20px;
  border: 1px ${theme.colors.white};
  border-radius: 4px;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  padding: 5px 10px;
`
export const CardsTableWrapper = customStyled(FlexBox)`
  flex-direction: column;
  margin-top: 8px;
  border: 1px ${theme.colors.white};
  border-radius: 6px 6px 0 0;
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(7.5px);
  padding: 16px;
  align-self: stretch;
  margin-top: 8px;
`

export const CardsItemWrapper = customStyled(FlexBox)`
  flex-direction: row;
  align-items: center;
`

export const Card = customStyled.div<{ scarcity: ScarcityTypes }>`
  position: relative;  
  width: 18px;
  height: 24px;
  border-radius: 4px;
  margin-right: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
  ${({ scarcity }) => ({
    background: cardColor[scarcity],
  })}
  &::before {
  content: '';
  display: flex;
  position: absolute;
  z-index: -1;
  top: -0.3px;
  left: -0.3px;
  height: 24px;
  width: 18.6px;
  border-radius: 4px;
  ${({ scarcity }) => ({
    background:
      scarcity === 'black' ? 'linear-gradient(180deg, #F9CF80 50%, transparent)' : 'transparent',
  })}
}
`

export const CardDetail = customStyled.span`
  color: ${theme.colors.wolfram};
  font-family: ${theme.fonts.secondary};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

export const CardValue = customStyled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fonts.secondary};
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  margin-left: auto;
  align-self: flex-start;
`

export const CardValueINR = customStyled.span`
  color: ${theme.colors.whites[6]};
  font-family: ${theme.fonts.secondary};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-left: auto;
  align-self: flex-start;
  padding-top: 2px;
`

export const CardsTotalWrapper = customStyled(FlexBox)`
  flex-direction: row;
  padding: 6px 16px 6px 44px;
  align-self: stretch;
  margin-bottom: 30px;
  align-items: center;
  border-radius: 2px 2px 6px 6px;
  border: 1px solid rgba(255, 255, 255, 0.04);
  background: rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(7.5px);
`
